






























































































































































import Vue from 'vue'
import {
  BCard, BRow, BCol, BFormInput, BTable, BPagination,
  BDropdown, BDropdownItem, BLink,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import { appFamiliesList } from '@/store/modules/app-families/app-families-list'
import { permissionSubjects } from '@/libs/acl/constants'

let debounceTimeout: ReturnType<typeof setTimeout> = setTimeout(() => '', 1000)

export default Vue.extend({
  name: 'FamiliesList',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BLink,

    vSelect,
    TableSpinner,
  },
  data() {
    return {
      currentRouteName: this.$router.currentRoute.name,
      permissionSubjects,
    }
  },
  computed: {
    tableColumns() {
      return appFamiliesList.getters.getTableColumns
    },
    perPage() {
      return appFamiliesList.getters.getPerPage
    },
    totalItems() {
      return appFamiliesList.getters.getTotalItems
    },
    currentPage: {
      get() {
        return appFamiliesList.getters.getCurrentPage
      },
      set(val: number) {
        appFamiliesList.mutations.SET_CURRENT_PAGE(val)
      },
    },
    perPageOptions() {
      return appFamiliesList.getters.getPerPageOptions
    },
    searchQuery: {
      get() {
        return appFamiliesList.getters.getSearchQuery
      },
      set(val: string) {
        appFamiliesList.mutations.SET_SEARCH_QUERY(val)
      },
    },
    sortBy: {
      get() {
        return appFamiliesList.getters.getSortBy
      },
      set(val: string) {
        appFamiliesList.mutations.SET_SORT_BY(val)
      },
    },
    isSortDirDesc: {
      get() {
        return appFamiliesList.getters.getIsSortDirDesc
      },
      set(val: boolean) {
        appFamiliesList.mutations.SET_IS_SORT_DESC(val)
      },
    },
    dataMeta() {
      return appFamiliesList.getters.getDataMeta
    },
  },
  watch: {
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }
      this.updateTable()
    },
    currentPage(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'currentPage', val)
      }
    },
    perPage(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'perPage', val)
      }
    },
    searchQuery(val, prevVal) {
      if (val !== prevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(this, this.currentRouteName, 'searchQuery', val)
        }, 250)
      }
    },
  },
  mounted() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  created() {
    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  methods: {
    updatePerPage(val: number) {
      appFamiliesList.mutations.SET_PER_PAGE(val)
    },
    async fetchFamiliesList() {
      return appFamiliesList.actions.fetchFamiliesList()
    },
    async deleteItem(id: string) {
      await appFamiliesList.actions.deleteFamily(id)
      this.updateTable()
    },
    setFilterParams() {
      appFamiliesList.mutations.SET_FILTER_PARAMS()
    },
    updateTable() {
      (this.$refs.refFamiliesListTable as BTable).refresh()
    },
  },
})
